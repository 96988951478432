import { HttpClient } from '@angular/common/http';
import { FamilyPortalConfig } from '../../family-portal-config';
import { Injectable } from '@angular/core';
import { GraphQLFamilyHelperService } from '../graphql-helpers/families/family-queries.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { IConvoDetailsRequestParams } from '../family-conversations-service/family-conversations-service';

@Injectable()
export class ApiService {
  private publicConfig;
  private NV_API_ORIGIN;
  public GRAPHQL_URL;

  constructor (
    private http: HttpClient,
    private familyPortalConfig: FamilyPortalConfig,
    private graphQlFamilyHelperService: GraphQLFamilyHelperService,
  ) {
    // Configs
    this.publicConfig = this.familyPortalConfig.publicConfig;
    this.NV_API_ORIGIN = this.publicConfig.NV_API_ORIGIN;

    // Endpoints
    this.GRAPHQL_URL = this.NV_API_ORIGIN + '/graphql-family-api';
  }

  getFamily (familyId: string): Observable<any> {
    const query = this.graphQlFamilyHelperService.getFamilyQuery({ familyId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  public updateFamilyConsent (familyId: string, patch: any): Observable<any> {
    const query = `mutation editFamilyConsent($patch: FamilyPatch!, $familyId: String!) {
      editFamilyConsent(patch: $patch, familyId: $familyId) {
        consentStatus
      }
    }`;
    const data = {
      query,
      variables: {
        patch,
        familyId,
      },
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  getCurrentUser (): any {
    const query = this.graphQlFamilyHelperService.getSignedInGuardianQuery();
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  getChildrenDbns (familyId: string): Observable<any> {
    const query = this.graphQlFamilyHelperService.getStudentDbnsQuery({ familyId });
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  getConversationTags$ (): any {
    const query = `{
      conversationTags {
        tagName
        tagType
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data).pipe(
      map(({ data: { conversationTags } }: { data: { conversationTags: { tagName: string }[] }}) => conversationTags),
      catchError(() => of([])),
    );
  }

  getFamilyConversations$ (familyId: string): any {
    const query = `{
      familyConversations(
        familyId: "${familyId}") {
        portalUserId
        conversationId
        header
        subheader
        lastMessageTimestamp
        unreadMessageCount
        isActive
        isReadOnly
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  getFamilyConsentDocument$ (familyId: string, language: string): any {
    const query = `{
      familyConsentDocument(familyId: "${familyId}", language: "${language}") {
        url
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  getFamilyConsentDocumentView$ (familyId: string, language: string): any {
    const query = `{
      familyConsentDocumentView(familyId: "${familyId}", language: "${language}") {
        url
        signedBy
        signedDate
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  getConversationHeader$ (conversationId: string, requestParams: IConvoDetailsRequestParams): any {
    const { familyId, caringAdultId } = requestParams;
    const query = `{
      conversationDetails(familyId: "${familyId}", contactPortalUserId: "${caringAdultId}", conversationId: "${conversationId}"){
        conversationId
        isReadOnly
        header
        subheader
        messagesCount
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  }

  getConversationMessages$ (conversationId: string, participantId: string, requestParams: IConvoDetailsRequestParams, page: number, pageSize: number): any {
    const { familyId, caringAdultId } = requestParams;
    // const startRow : number = (page - 1) * pageSize || 0;
    // const limit : number = pageSize || 3;
    const query = `{
      conversationDetails(familyId: "${familyId}", contactPortalUserId: "${caringAdultId}", conversationId: "${conversationId}", pageNumber: ${page}, pageSize: ${pageSize}){
        conversationId
        isReadOnly
        messagesCount
        messages {
            id
            content
            sentBy
            firstName
            lastName
            metadata
            timestamp
            hasBeenRead
          }
        }
      }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post(this.GRAPHQL_URL, data);
  };

  public sendConversationMessage$ ({
    familyId,
    caringAdultId,
    messageContent,
    messageMetadata,
    conversationId,
    participantId,
  }): any {
    const conversationPayload = {
      conversationId,
      entityId: familyId,
      entityType: 'family',
      isPrivate: true,
      isActive: true,
      createdAt: new Date().toISOString(),
    };

    const message = {
      conversationId,
      sentBy: participantId,
      content: messageContent,
      metadata: messageMetadata,
    };

    const participants = [{
      conversationId,
      participantId,
      participantType: 'GUARDIAN',
      isPrimary: false,
    }, {
      conversationId,
      participantId: caringAdultId,
      participantType: 'CARING_ADULT',
      isPrimary: true,
    }];

    const conversationDetailInput = {
      conversation: conversationPayload,
      message,
      participants,
    };

    const query = `mutation sendConversationMessage($patch: ConversationDetailInput!) {
      sendConversationMessage(patch: $patch) {
        id
        conversationId
        sentBy
        content
        metadata
        timestamp
      }
    }`;

    const data = {
      query,
      fetchPolicy: 'no-cache',
      variables: { patch: conversationDetailInput },
    };
    return this.http.post(this.GRAPHQL_URL, data);
  };

  translate (text: string[], target: string) {
    const query = this.graphQlFamilyHelperService.getTranslateQuery(text, target);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<{ data: any }>(this.GRAPHQL_URL, data).pipe(
      map(({ data }) => {
        return data.translate;
      }),
      catchError(err => throwError(err)));
  }

  getTranslations (target: string) {
    const query = this.graphQlFamilyHelperService.getTranslationsQuery(target);
    const data = {
      query,
      fetchPolicy: 'no-cache',
    };
    return this.http.post<{ data: any }>(this.GRAPHQL_URL, data).pipe(
      map(({ data }) => {
        return data.translations;
      }),
      catchError(err => throwError(err)));
  }
}
