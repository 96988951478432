
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ApiService } from '../api/api-service/api-service';
import { catchError, switchMap, startWith, filter, tap } from 'rxjs/operators';
import { TranslocoService } from '@jsverse/transloco';
import { camelCase } from 'lodash';
import { FamilyPortalConfig } from '../family-portal-config';

export const similarkeyWarning = (key: string, lang: string) => {
  const standardizedKey = camelCase(key);
  const standardizedStaticKeys = JSON.parse(sessionStorage.getItem('standardizedStaticKeys')) || {};
  const match = standardizedStaticKeys[standardizedKey];
  if (match && match.original !== key) {
    const sanitizedKey = key.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    const sanitizedMatch = match.original.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    console.warn(`The key "${sanitizedKey}" is being dynamically translated. If you are attempting to use the professional translation, please use the key "${sanitizedMatch}" instead.`);
  }
};

@Injectable()
export class TranslateService {
  constructor (
    private apiService: ApiService,
    private translocoService: TranslocoService,
    private familyPortalConfig: FamilyPortalConfig,
  ) {}

  private loadedTranslations = {
    en: true, // english is always loaded
  };

  getActiveLang () {
    return this.translocoService.getActiveLang();
  }

  getDefaultLang () {
    return this.translocoService.getDefaultLang();
  }

  switchLanguage (lang: string) {
    const activeLang = this.getActiveLang();
    if (activeLang !== lang) this.translocoService.setActiveLang(lang || 'en');
  }

  getTranslatedFromCacheAndNonCachedKeys (text: string[], activeLang: string) {
    const tranlatedKeysFromCache: string[] = [];
    const nonCachedKeys: string[] = [];
    text.forEach(t => {
      const cached = this.translocoService.getTranslation(activeLang)[t];
      if (cached) tranlatedKeysFromCache.push(cached);
      else nonCachedKeys.push(t);
    });
    return { tranlatedKeysFromCache, nonCachedKeys };
  };

  getFromCacheOrApi (text: string[], activeLang: string) {
    const { DEV_MODE } = this.familyPortalConfig.publicConfig;
    if (DEV_MODE) {
      text.forEach(key => similarkeyWarning(key, activeLang));
    }
    const { tranlatedKeysFromCache, nonCachedKeys } = this.getTranslatedFromCacheAndNonCachedKeys(text, activeLang);
    if (nonCachedKeys.length) {
      return this.apiService.translate(nonCachedKeys, activeLang).pipe(
        tap((translations) => {
          nonCachedKeys.forEach((t, i) => {
            this.translocoService.setTranslationKey(t, translations[i], { emitChange: false });
          });
        }),
        switchMap(() => {
          const translated = text.map(t => this.translocoService.getTranslation(activeLang)[t]);
          return of(translated);
        }),
      );
    } else {
      return of(tranlatedKeysFromCache);
    }
  };

  translateText (text: string[]) {
    return this.translocoService.events$.pipe(
      tap((e) => {
        if (e.type === 'translationLoadSuccess' || e.type === 'translationLoadFailure') {
          this.loadedTranslations[e.payload.langName] = true;
        }
      }),
      startWith(null), // allow the text to be translated when method is called even if transloco events have not fired
      filter(() => {
        const activeLang = this.getActiveLang();
        return this.loadedTranslations[activeLang];
      }),
      switchMap(() => {
        const activeLang = this.getActiveLang();
        const defaultLang = this.getDefaultLang();
        // if the active language is the default language, return the text as is
        if (activeLang === defaultLang) {
          return of(text);
        }
        return this.getFromCacheOrApi(text, activeLang);
      }),
      catchError((err) => {
        // TODO report error to rollbar when rollbar service is available in the family-portal
        console.error('Error getting translations: ', err);
        return of(text);
      }),
    );
  };

  standardizeKeys (keys: string[]) {
    const standardizedKeys: { [key: string]: { original: string } } = keys.reduce((acc, key) => {
      const standardized = camelCase(key);
      acc[standardized] = {
        original: key,
      };
      return acc;
    }, {});
    return standardizedKeys;
  };
}
